import React, { useEffect } from "react";

const Pricing = () => {
  useEffect(() => {
    window.scrollTo(0, 0);

    // Dynamically load Stripe script
    const script = document.createElement("script");
    script.src = "https://js.stripe.com/v3/pricing-table.js";
    script.async = true;

    // Append Stripe script to the body
    document.body.appendChild(script);

    // Cleanup: remove Stripe script when the component unmounts
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <div className="w-full bg-[#f3fff4] relative">
      <div className="w-full relative">
        <img
          alt="banner"
          src="/assets/images/common-banner.png"
          className="w-full relative h-[150px] lg:h-[550px]"
        />
        <div className="w-full p-4 absolute top-0 lg:top-[250px] left-0 right-0 mx-auto">
          <h1 className="text-2xl lg:text-[60px] font-['Montserrat'] leading-[70px] text-white inline-block relative">
            Sign Up
            <span className="font-bold relative ml-3 z-10">
              <img
                alt="line"
                src="/assets/images/blog/line-through.png"
                className="w-full absolute top-[-6px] lg:-top-[20px] left-0 z-[-1]"
              />
            </span>
          </h1>
        </div>
      </div>

      <div className="w-full max-w-screen-xl mx-auto my-10 border-b-2 border-[#d9d9d9] py-10">
        <div className="w-full p-4">
          <div>
            <stripe-pricing-table pricing-table-id="prctbl_1Q8Qym2Ne58wht27koJnbfsE"
              publishable-key="pk_live_51Q6F292Ne58wht27e9FJGvY2mdjDLkyk17JosU5LNsZ37P7AfWaM5O3Ttt78ZJwgVzu4VtfIScun05oeVLGFxM5C00WKReOVMs">
            </stripe-pricing-table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Pricing;