import { Routes, Route, useLocation } from "react-router-dom";
import "./App.css";
import Header from "./components/header/header";
import Footer from "./components/footer/footer";
import TopBar from "./components/topbar/topbar";

// Views
import Home from "./views/home/home";
import Blogs from "./views/blogs/blogs";
import WhatIsAi from "./views/what-is-ai/what-is-ai";
import Testimonial from "./views/testimonial/testimonial";
import OurSolution from "./views/our-solution/our-solution";
import Team from "./views/team/team";
// Added new views
import TermsOfUse from "./views/terms-of-use/terms-of-use";
import PrivacyPolicy from "./views/privacy-policy/privacy-policy";
import LoginSignup from "./views/login-signup/login-signup";
import Pricing from "./views/pricing/pricing";
import LogIn from "./views/login/login";
import { useEffect, useState } from "react";

// adding vercel analytics
import { Analytics } from '@vercel/analytics/react';

// adding react-helmet-async
import { Helmet, HelmetProvider } from "react-helmet-async";

function App() {
  const [loader, setloader] = useState(true);
  const [isMobile, setIsMobile] = useState(false);
  const location = useLocation();

  const excludePaths = ['/terms-of-use', '/privacy-policy', '/pricing', '/login'];

  useEffect(() => {
    setloader(true);

    // Check if it's a mobile device
    const checkMobile = () => {
      const userAgent = navigator.userAgent || navigator.vendor || window.opera;
      // Basic check for mobile devices
      if (/android|iphone|ipad|ipod|blackberry|iemobile|opera mini/i.test(userAgent.toLowerCase())) {
        setIsMobile(true);
      }
    };

    checkMobile();
    const timeoutDuration = isMobile ? 2000 : 8000;
    setTimeout(() => {
      setloader(false);
    }, timeoutDuration);
  }, []);

  if (loader && !excludePaths.includes(location.pathname)) {
    return (
      <div className="w-full h-screen flex items-center justify-center bg-[#01030c]">
        {isMobile ? (
           <img src="/assets/images/logo.png" alt="color-favi" className="animate-pulse" />
        ) : (
          <video src="/assets/videos/video3.mp4" muted autoPlay />
        )}
      </div>
    );
  } else {
    return (
      <HelmetProvider>
        <div className="App">
          <Helmet>
            <title>DeltaClaims.AI | AI-Driven Claims Adjustment and Management Services</title>
            <meta name="viewport" content="width=device-width, initial-scale=1.0" />
            <meta name="description" content="DeltaClaims.AI offers cloud-native, AI-powered solutions for insurance claims adjustment and management." />
            <meta name="keywords" content="AI claims adjustment, insurance claims automation, cloud-native claims processing" />
            <meta name="robots" content="index,follow" />
            <meta name="theme-color" content="#00193d" />
            <meta property="og:title" content="DeltaClaims.AI - Revolutioning the Claims Industry. Save Time. Get Paid on Every Line." />
            <meta property="og:description" content="AI-powered claims adjustment solutions for faster, more efficient claims management." />
            <meta property="og:url" content="https://www.deltaclaims.ai" />
            <meta property="og:image" content="https://www.deltaclaims.ai/assets/images/social-media-logo.png" />
            <meta property="og:type" content="website" />
            <meta property="og:site_name" content="DeltaClaims.AI" />
            <meta property="og:locale" content="en_US" />
            <meta name="twitter:card" content="summary_large_image" />
            <meta property="twitter:domain" content="deltaclaims.ai" />
            <meta property="twitter:url" content="https://www.deltaclaims.ai" />
            <meta name="twitter:title" content="DeltaClaims.AI - Revolutioning the Claims Industry. Save Time. Get Paid on Every Line." />
            <meta name="twitter:description" content="AI-powered claims adjustment solutions for faster, more efficient claims management." />
            <meta name="twitter:image" content="https://www.deltaclaims.ai/assets/images/social-media-logo.png" />
          </Helmet>
          <div className="lg:absolute w-full top-0 left-0 right-0 mx-auto xl:z-[400]">
            {/*<TopBar />*/}
            <Header />
            <Analytics />
          </div>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/our-solution" element={<OurSolution />} />
            <Route path="/blog" element={<Blogs />} />
            <Route path="/team" element={<Team />} />
            <Route path="/what-is-ai" element={<WhatIsAi />} />
            <Route path="/testimonials" element={<Testimonial />} />
            {/* Added new routes */}
            <Route path="/terms-of-use" element={<TermsOfUse />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="/login-signup" element={<LoginSignup />} />
            <Route path="/pricing" element={<Pricing />} />
            <Route path="/login" element={<LogIn />} />
          </Routes>
          <Footer />
        </div>
      </HelmetProvider>
    );
  }
}

export default App;