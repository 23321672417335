import React, { useEffect } from "react";
import { NavLink, useLocation } from "react-router-dom";

function Footer() {
  const location = useLocation();
  useEffect(() => {
    console.log(location.pathname);
  }, [location]);

  return (
    <footer
      className={`
        w-full relative
        ${(location.pathname === "/") && ("-mt-[350px]")} 
        ${(location.pathname === "/blog") ||
          (location.pathname === '/our-solution') ||
          (location.pathname === '/testimonials') ? ("bg-[#f3fff4]") : ('')}
      `}
    >
      <img
        alt="footer-banner"
        className={"absolute left-0 top-[20%]"}
        src="/assets/images/footer/fotter-shape-1.png"
      />
      <img
        alt="footer-banner"
        className={"absolute right-0 top-[20%]"}
        src="/assets/images/footer/fotter-shape-2.png"
      />
      <img
        alt="footer-banner"
        className={"w-full h-full -mb-1"}
        src="/assets/images/footer/fotter-upper-shape.png"
      />
      <div className={`w-full bg-[#130f29] ${(location.pathname === "/") ? ("pt-[300px]") : ("pt-10")}`}>
        <div className={`w-full mx-auto max-w-screen-xl text-white text-left p-4 xl:p-0  relative z-20`}>
          <div className="flex flex-wrap lg:flex-nowrap gap-8 xl:gap-10">
            <div className="w-full lg:w-5/12 xl:w-6/12">
              <NavLink to="/" className={"relative z-50"}>
                <img src="/assets/images/logo.png" alt="text" className="block mx-auto lg:mx-0" />
              </NavLink>
              <p className="text-base font-['Montserrat'] py-10">
                Our purpose is to empower claims professionals through innovative,
                tailor-made AI solutions. Our AI simplifies the complex world of
                insurance claims assisting insurance professionals and general 
                contractors with efficient, secure, and accurate information about
                their claim.
              </p>
              <div className="flex flex-wrap lg:flex-nowrap gap-4">
                <img src="/assets/images/footer/fb.png" alt="" />
                <img src="/assets/images/footer/insta.png" alt="" />
                <img src="/assets/images/footer/x.png" alt="" />
                <img src="/assets/images/footer/youtube.png" alt="" />
              </div>
            </div>
            <div className="w-full lg:w-3/12 xl:w-3/12">
              <h2 className="text-[25px] font-bold font-['Montserrat'] inline-block">
                Quick Links
                <hr style={{
                  "background": "-webkit-linear-gradient(90deg, rgb(89,187,102) 25%, rgb(1,149,216) 75%)",
                  "background": "-o-linear-gradient(90deg, rgb(89,187,102) 25%, rgb(1,149,216) 75%)",
                  "background": "-moz-linear-gradient(90deg, rgb(89,187,102) 25%, rgb(1,149,216) 75%)",
                  "background": "linear-gradient(90deg, rgb(89,187,102) 25%, rgb(1,149,216) 75%)",
                }} className="bg-gradient-to-r from-[#59bb66] to-[#0195d8] h-px my-4 lg:my-10 bg-gray-200 border-0 w-16"></hr>
              </h2>
              <ul className="flex flex-col text-base font-['Montserrat'] p-0">
                <li>
                  <NavLink onClick={() => window.scrollTo({top:0, behavior: 'smooth'})} to="/" className="block py-2 text-white rounded">
                    Home
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    onClick={() => window.scrollTo({top:0, behavior: 'smooth'})}
                    to="/our-solution"
                    className="block py-2 text-white rounded"
                  >
                    Our Solutions
                  </NavLink>
                </li>
                <li>
                  <NavLink onClick={() => window.scrollTo({top:0, behavior: 'smooth'})} to="/testimonials" className="block py-2 text-white rounded">
                    Testimonials
                  </NavLink>
                </li>
                <li>
                  <NavLink onClick={() => window.scrollTo({top:0, behavior: 'smooth'})}
                    to="/blog"
                    className="block py-2 text-white rounded"
                  >
                    Blogs
                  </NavLink>
                </li>
                <li>
                  <NavLink onClick={() => window.scrollTo({top:0, behavior: 'smooth'})}
                    to="/team"
                    className="block py-2 text-white rounded"
                  >
                    Meet The Team
                  </NavLink>
                </li>
                <li>
                  <NavLink onClick={() => window.scrollTo({top:0, behavior: 'smooth'})}
                    to="/what-is-ai"
                    className="block py-2 text-white rounded"
                  >
                    What Is AI
                  </NavLink>
                </li>
              </ul>
            </div>
            <div className="w-full lg:w-4/12 xl:w-3/12">
              <h2 className="text-[25px] font-bold font-['Montserrat'] inline-block">
                Get in Touch
                <hr style={{
                  "background": "-webkit-linear-gradient(90deg, rgb(89,187,102) 25%, rgb(1,149,216) 75%)",
                  "background": "-o-linear-gradient(90deg, rgb(89,187,102) 25%, rgb(1,149,216) 75%)",
                  "background": "-moz-linear-gradient(90deg, rgb(89,187,102) 25%, rgb(1,149,216) 75%)",
                  "background": "linear-gradient(90deg, rgb(89,187,102) 25%, rgb(1,149,216) 75%)",
                }} className="bg-gradient-to-r from-[#59bb66] to-[#0195d8] h-px my-4 lg:my-10 bg-gray-200 border-0 w-16"></hr>
              </h2>
              <div className="flex gap-4 mb-6">
                <img
                  className="w-5 h-5 mt-1"
                  src="/assets/images/footer/location.png"
                  alt=""
                />
                <div>
                  <h3 className="font-semibold font-['Montserrat'] text-[20px]">
                    Location
                  </h3>
                  <p className="text-base font-['Montserrat'] mt-4">
                    DeltaClaims.AI 6300 NE 1st Ave. suite 202 Fort Lauderdale, FL
                    33334
                  </p>
                </div>
              </div>
              <div className="flex gap-4 mb-6">
                <img
                  className="w-5 h-5 mt-1"
                  src="/assets/images/footer/phone.png"
                  alt=""
                />
                <div>
                  <h3 className="font-semibold font-['Montserrat'] text-[20px]">
                    Phone
                  </h3>
                  <p className="text-2xl lg:text-[34px] font-['Montserrat'] font-bold mt-4">
                    <a href="tel:(877) Delta-01">
                      (877) Delta-01
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="w-full md:flex md:items-center md:justify-between py-4 mt-4 lg:py-10 lg:mt-10 border-t border-white">
            <span className="text-sm sm:text-center">
              © {new Date().getFullYear() + " "}
              <a href="/" className="hover:underline">
                DeltaClaims.AI
              </a>
              . All Rights Reserved.
            </span>
            <ul className="flex flex-wrap items-center mt-3 text-sm font-medium sm:mt-0">
              <li>
                <NavLink to="/terms-of-use" className="hover:underline me-4 md:me-6">
                  Terms of Use
                </NavLink>
              </li>
              <li>
                <NavLink to="/privacy-policy" className="hover:underline me-4 md:me-6">
                  Privacy Policy
                </NavLink>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <img
        alt="footer-banner"
        className={"w-full h-full -mt-1"}
        src="/assets/images/footer/fotter-bottom.png"
      />
    </footer>
  );
}

export default Footer;
