import React, { useEffect } from "react";

const LogInSignUp = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="w-full bg-[#f3fff4] relative">
      <div className="w-full relative">
        <img
          alt="banner"
          src="/assets/images/common-banner.png"
          className="w-full relative h-[150px] lg:h-[550px]"
        />
        <div className="w-full p-4 absolute top-0 lg:top-[250px] left-0 right-0 mx-auto">
          <h1 className="text-2xl lg:text-[60px] font-['Montserrat'] leading-[70px] text-white inline-block relative">
            Log In / Sign Up
            <span className="font-bold relative ml-3 z-10">
              <img
                alt="line"
                src="/assets/images/blog/line-through.png"
                className="w-full absolute top-[-6px] lg:-top-[20px] left-0 z-[-1]"
              />
            </span>
          </h1>
        </div>
      </div>

      <div className="w-full max-w-screen-xl mx-auto my-10 border-b-2 border-[#d9d9d9] py-10">
        <div className="w-full p-4">
          <h2 className="text-center text-xl lg:text-[22px] font-bold">Coming Soon</h2>
          <p className="text-center text-md lg:text-lg mt-6">October 2024</p>
        </div>
      </div>
    </div>
  );
};

export default LogInSignUp;
